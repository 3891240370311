import type { ApiServerOptions } from '@mechhive/api';
import { createApi } from '@mechhive/api'
import type { RewarbleCardUpdateDto } from './dto/rewarble/RewarbleCardUpdateDto';
import type { RewarbleCardActivateDto } from './dto/rewarble/RewarbleCardActivateDto';
import type { RewarbleProduct } from './types/rewarble/RewarbleProduct';
import type { RewarbleCryptoCalculate } from './dto/rewarble/RewarbleCryptoCalculateDto';
import type { RewarbleCryptoCalculation } from './types/rewarble/RewarbleCryptoCalculation';
import type { RewarbleSupportReviewDto } from './dto/rewarble/RewarbleSupportReviewDto';
import type { UserRequestDto } from './dto/internal/user.request.dto';
import type { RewarbleCardApproval } from './types/rewarble/RewarbleCardApproval';
import type { StoryblokStoryResponse } from './types/storyblok/StoryblokStoryResponse';

export const createInternalApi = ( options?: Omit<ApiServerOptions, 'name'> ) => {
  const api = createApi( {
    name: 'internal',
    ...options,
    defaultHeaders: {
      'content-type': 'application/json'
    }
  } );
  
  return {
    account: {
      patchLanguage( lang: string ) {
        return api.patch( '/api/account/language', {
          data: {
            lang
          }
        } )
      },
    },
    cards: {
      updateCards( cardId: number, data: RewarbleCardUpdateDto ) {
        return api.patch( `/api/cards/${cardId}`, {
          data
        } )
      },
      activateCard( cardId: number, data: RewarbleCardActivateDto ) {
        return api.post( `/api/cards/${cardId}/activate`, {
          data
        } )
      },
      createTestApproval( cardId: number ) {
        return api.post( `/api/cards/${cardId}/approval/test` )
      },
      hideCard( cardId: number ) {
        return api.post( `/api/cards/${cardId}/hide` )
      },
      getApproval( cardId : number ) {
        return api.get<Array<RewarbleCardApproval>>( `/api/cards/${cardId}/approval` )
      }
    },
    content: {
      get<T, R = any>( slug: string, locale: string ) {
        return api.get<StoryblokStoryResponse<T, R>>( '/api/content', {
          params: {
            slug,
            locale
          }
        } )
      }
    },
    crypto: {
      calculate( data: RewarbleCryptoCalculate ) {
        return api.post<RewarbleCryptoCalculation>( '/api/crypto/calculate', {
          data
        } )
      }
    },
    google: {
      maps: {
        place: {
          autocomplete( params: Record<string, string | number> ) {
            return api.get<{
              predictions: Array<{
                description: string;
                place_id: string;
                reference: string;
                structured_formatting: {
                  main_text: string;
                  secondary_text: string;
                }
              }>,
              status: string;
            }>( '/api/google/maps/place/autocomplete', {
              params
            } )
          },
          details( placeId: string ) {
            return api.get<{
              result: {
                address_components: Array<{
                  long_name: string;
                  short_name: string;
                  types: Array<string>
                }>,
                adr_address: string;
                formatted_address: string;
                geomtry: object;
                icon: string;
                icon_background_color: string;
                icon_mask_base_uri: string;
                name: string;
                place_id: string;
                reference: string;
                types: Array<any>;
                url: string;
                utc_offset: number;
                vicinity: string;
              },
              status: string;
            }>( '/api/google/maps/place/details', {
              params: {
                placeId: placeId
              }
            } )
          }
        }
      }
    },
    support: {
      createReview( data: RewarbleSupportReviewDto ) {
        return api.post( '/api/support/review', {
          data
        } )
      }
    },
    transaction: {
      getStatus( trx: string ) {
        return api.get<{ status: string }>( `/api/transaction/${trx}/status` );
      },
      validateProduct( productSlug: string, deviceFingerprint?: string ) {
        const params = {
          productSlug
        };

        if ( deviceFingerprint ) {
          params[ 'deviceFingerprint'] = deviceFingerprint;
        }
        
        return api.get<RewarbleProduct>( '/api/transaction/validateProduct', {
          params
        } )
      }
    },
    user: {
      request( data: UserRequestDto ) {
        const path = '/api/user/request';
        if ( typeof navigator !== 'undefined' && typeof navigator.sendBeacon === 'function' ) {
          navigator.sendBeacon( path, JSON.stringify( data ) )
          return;
        }

        return api.post( path, {
          data
        } );
      }
    },
    webhook: {
      cache( data: { action: 'purge', entities: Array<'products'| 'fx'| 'region'> } ) {
        return api.post( '/webhooks/cache', {
          data
        } )
      }
    }
  }
}
